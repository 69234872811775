// adds `required` attribute to one from the set of checkboxes(if all unchecked)
// and on html5 vaildation shows custom message for the group

const prepareCheckboxesGroup = function (group, firstCheckbox) {
  if (!firstCheckbox) return

  if (group.querySelector('input[type="checkbox"]:checked')) {
    firstCheckbox.removeAttribute('required')
    firstCheckbox.setCustomValidity('')
  } else {
    firstCheckbox.setAttribute('required', true)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('[data-behavior=required-checkboxes-group]')
    .forEach((group) => {
      let firstCheckbox = group.querySelector(
        'input[type="checkbox"]:first-child'
      )

      if (group.dataset?.invalidMessage) {
        firstCheckbox.addEventListener('change', () => {
          firstCheckbox.setCustomValidity('')
        })

        firstCheckbox.oninvalid = function (e) {
          if (!e.target.validity.valid) {
            e.target.setCustomValidity(group.dataset.invalidMessage)
          }
        }
      }

      prepareCheckboxesGroup(group, firstCheckbox)

      group.addEventListener('change', () => {
        prepareCheckboxesGroup(group, firstCheckbox)
      })
    })
})
